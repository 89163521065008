import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

import { H, P, Wrapper } from '@farewill/ui'
import { screenMax } from '@farewill/ui/helpers/responsive'
import { BORDER_RADIUS, COLOR, FONT } from '@farewill/ui/tokens'
import { GTR } from '@farewill/ui/tokens/layout'

const StyledWrapper = styled(Wrapper)`
  border-radius: ${BORDER_RADIUS.XL};
  padding: ${GTR.M} ${GTR.L} ${GTR.L};
`

const StyledP = styled(P)`
  font-size: ${FONT.SIZE.L};

  ${screenMax.s`
 font-size: ${FONT.SIZE.M};
`}
`

export const PartnerCustomMessage = ({
  partnerDisplayName,
  partnerCustomMessage,
}: {
  partnerDisplayName: string
  partnerCustomMessage: string
}): React.ReactElement => {
  const paragraphs = partnerCustomMessage
    .split('\n')
    .filter((line) => line.length > 0) // Remove empty lines

  return (
    <StyledWrapper background={COLOR.BACKGROUND.FOG}>
      <H size="M">A message from {partnerDisplayName}</H>
      {paragraphs.map((para) => (
        <StyledP key={paragraphs.indexOf(para)}>
          <ReactMarkdown
            components={{
              // eslint-disable-next-line react/display-name, react/prop-types
              p: ({ children }) => <>{children}</>,
            }}
          >
            {para}
          </ReactMarkdown>
        </StyledP>
      ))}
    </StyledWrapper>
  )
}
