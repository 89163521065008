import React from 'react'
import { getSessionStorage } from 'lib/storage/sessionStorage'
import ProductFlowButton, { CtaButtonProps } from 'components/ProductFlowButton'
import { QueryParams } from 'components/ProductFlowButton/helpers'

const WillsProductFlowButton = ({
  children,
  queryParams,
  ...props
}: CtaButtonProps & QueryParams): React.ReactElement => {
  const storageParams = getSessionStorage<{ code: string }>('urlParams')
  const code = storageParams?.code
  const queryParameters = {
    code,
    isPartnerCampaignReferral: queryParams?.isPartnerCampaignReferral,
    telephoneWillsEnabled: queryParams?.telephoneWillsEnabled,
    preselectedService: queryParams?.preselectedService,
  } as QueryParams

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ProductFlowButton queryParams={queryParameters} {...props}>
      {children}
    </ProductFlowButton>
  )
}

export default WillsProductFlowButton
