import type { PreselectedServiceValues } from 'lib/url/wills/constants'

export type QueryParams = {
  preselectedService?: PreselectedServiceValues
  isPartnerCampaignReferral?: boolean
  telephoneWillsEnabled?: boolean
  code?: string
}

export const getQueryParams = ({
  preselectedService,
  isPartnerCampaignReferral,
  telephoneWillsEnabled,
  code,
}: QueryParams): Record<string, string> => {
  const queryObject = {
    ...(preselectedService && { preselected_service: preselectedService }),
    ...(isPartnerCampaignReferral && {
      partner_campaign_referral: 'true',
      telephone_wills_enabled: telephoneWillsEnabled ? 'true' : 'false',
    }),
    ...(code && { code }),
  }

  return queryObject
}
