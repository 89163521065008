import React from 'react'
import { Button } from '@farewill/ui'
import { ButtonProps } from '@farewill/ui/components/Button/types'

import { FAREWILL_ACCOUNT_APP_URL } from 'config'
import { getComposedHref } from 'lib/url/getComposedHref'

import { getQueryParams, QueryParams } from './helpers'

export type CtaButtonProps = {
  buttonType?: 'White' | 'Primary' | 'Bordered'
  children: React.ReactNode
  href: string
  queryParams?: QueryParams
  buttonProps?: ButtonProps
}

const ProductFlowButton = ({
  buttonType = 'White',
  children = 'Let’s get started',
  href = FAREWILL_ACCOUNT_APP_URL,
  queryParams = { isPartnerCampaignReferral: false },
  buttonProps,
}: CtaButtonProps & QueryParams): React.ReactElement => {
  let Component: typeof Button.Plain
  switch (buttonType) {
    case 'Primary':
      Component = Button.Primary
      break
    case 'Bordered':
      Component = Button.Bordered
      break
    default:
      Component = Button.White
  }

  return (
    <Component
      wide
      href={getComposedHref<{ [QueryParam in keyof QueryParams]: string }>(
        href,
        getQueryParams(queryParams)
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      {children}
    </Component>
  )
}

export default ProductFlowButton
