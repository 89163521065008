import { PreselectedServiceValues } from './constants'

/**
 * At the moment ProductLink is only designed to send users to the triage flow
 * on the Wills app which only supports `online` and `telephone` values for
 * the preselectedService.
 */
export function preselectedServiceIsValid(
  value: PreselectedServiceValues | undefined
): value is 'online' | 'telephone' {
  return value === 'online' || value === 'telephone'
}
