import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Button, CrossIcon } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'

import AspectRatioWrapper from 'components/AspectRatioWrapper'

import Modal from '.'

const StyledModalWrapper = styled(Wrapper)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const StyledModalContent = styled.div`
  background: ${COLOR.BLACK};
  position: relative;
  width: 100%;
  max-width: 900px;
  pointer-events: all;
`

const StyledCloseButton = styled(Button.Plain)`
  position: absolute;
  bottom: 100%;
  right: -${GTR.S};
  color: ${COLOR.WHITE};
  font-weight: ${FONT.WEIGHT.BOLD};

  && {
    padding: ${GTR.S};
  }
`

const StyledArrowIcon = styled(CrossIcon)`
  vertical-align: bottom;
`

const MediaModal = ({ aspectRatio, close, children }) => (
  <Modal close={close}>
    <StyledModalWrapper padding="M" paddingFromM="L">
      <StyledModalContent>
        <StyledCloseButton
          type="button"
          onClick={close}
          icon={<StyledArrowIcon size="L" />}
        >
          Close
        </StyledCloseButton>

        {aspectRatio ? (
          <AspectRatioWrapper ratio={aspectRatio}>
            {children}
          </AspectRatioWrapper>
        ) : (
          children
        )}
      </StyledModalContent>
    </StyledModalWrapper>
  </Modal>
)

MediaModal.defaultProps = {
  aspectRatio: 16 / 9,
}

MediaModal.propTypes = {
  aspectRatio: PropTypes.number,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default MediaModal
