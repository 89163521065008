import React, { useState } from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import rehypeSanitize from 'rehype-sanitize'
import {
  Grid,
  H,
  BackgroundImageWrapper,
  Button,
  PlaySolidIcon,
} from '@farewill/ui'
import { GTR, COLOR } from '@farewill/ui/tokens'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import { paragraphL } from '@farewill/ui/helpers/text'

import type { PreselectedServiceValues } from 'lib/url/wills/constants'
import { preselectedServiceIsValid } from 'lib/url/wills/helpers'

import AspectRatioWrapper from 'components/AspectRatioWrapper'
import MediaModal from 'components/Modal/MediaModal'
import LinkButton from 'components/LinkButton'
import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'
import { QueryParams } from 'components/ProductFlowButton/helpers'
import { WILLS_NEW_TRIAGE_FLOW_FRAMING_URL, WILLS_SIGN_UP_PATH } from 'config'
import PreventOrphan from 'components/PreventOrphan'
import { PartnerCustomMessage } from 'components/PartnerCustomMessage'
import WillsProductFlowButton from '../WillsProductFlowButton'

const StyledHero = styled.section`
  position: relative;

  ${screenMax.s`
    && {
      margin-bottom: calc(-${GTR.L} - 1px);
    }
  `}
`

const StyledContentWrapper = styled(Grid.Item)<{ $limitWidth: boolean }>`
  position: relative;

  ${({ $limitWidth }) =>
    $limitWidth &&
    screenMin.l`
      max-width: 500px;
  `}
`

const StyledSubtitle = styled.div`
  p {
    ${paragraphL}
  }
`

const StyledTrustpilotSummary = styled(TrustpilotSummary)`
  margin-top: ${GTR.M};
`

const StyledAsideWrapper = styled(Grid.Item)`
  width: 100%;

  ${screenMin.m`
    max-width: 400px;
    align-self: center;
    justify-self: center;

    && {
      margin: -10% 0;
    }
  `}
`

const StyledBlob = styled(BackgroundImageWrapper)`
  width: 100%;
  text-align: center;

  ${screenMax.s`
    margin: 0 -${GTR.M};
    width: calc(100% + ${GTR.M} + ${GTR.M});
    border-bottom: 1px solid ${COLOR.GREY.LIGHT};
    background-position: top;
    background-size: 120%;
    padding-bottom: ${GTR.M};
  `}

  ${screenMin.m`
    height: 0;
    padding-top: 10%;
    padding-bottom: 90%;
  `}
`

const StyledImage = styled(BackgroundImageWrapper)`
  ${screenMin.l`
    margin-left: -25px;
    width: calc(50px + 100%);
  `};
`

const StyledTVAdButton = styled(Button)`
  color: ${COLOR.BLACK};
  background: bottom/contain no-repeat
    url("data:image/svg+xml,%3Csvg width='189' height='3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1c10.638.708 21.21 1.24 31.875 1.208 26.255-.08 52.51-.505 78.764-.708 25.601-.198 51.265-.776 76.861 0' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

  && {
    padding: ${GTR.XXS} ${GTR.XS} ${GTR.XXS} 0;
  }
`

const StyledPartnerCustomMessageWrapper = styled(Grid.Item)`
  width: 100%;

  ${screenMin.m`
    display: none;
  `}
`

const Hero = ({
  title = 'The UK’s #1 online will writing service',
  subtitle = 'Write your will online in as little as 15 minutes and take care of what really matters.',
  ctaText = 'Let’s get started',
  isPartnerCampaignPage = false,
  preselectedService,
  telephoneWillsEnabled = false,
  isExperimentPage = false,
  partnerCustomMessage,
  partnerDisplayName,
}: {
  title?: string
  subtitle?: string
  ctaText?: string
  isPartnerCampaignPage?: boolean
  preselectedService?: PreselectedServiceValues
  telephoneWillsEnabled?: boolean
  isExperimentPage?: boolean
  partnerCustomMessage?: string | null
  partnerDisplayName?: string
}): React.ReactElement => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <StyledHero>
      <Grid>
        <StyledContentWrapper
          $limitWidth={title === 'The UK’s #1 online will writing service'}
          spanFromM={6}
        >
          <H size="XL" decorative>
            <PreventOrphan>{title}</PreventOrphan>
          </H>

          <StyledSubtitle>
            <ReactMarkdown
              rehypePlugins={
                [rehypeRaw, rehypeSanitize] as ReactMarkdown.PluggableList
              }
            >
              {subtitle}
            </ReactMarkdown>
          </StyledSubtitle>
          {isExperimentPage ? (
            <WillsProductFlowButton
              buttonType="Primary"
              buttonProps={{
                'data-track-event': 'click',
                'data-track-element': 'cta',
                'data-track-slice': 'hero',
              }}
              href={WILLS_NEW_TRIAGE_FLOW_FRAMING_URL}
              queryParams={
                {
                  isPartnerCampaignReferral: isPartnerCampaignPage,
                  telephoneWillsEnabled,
                } as QueryParams
              }
            >
              {ctaText}
            </WillsProductFlowButton>
          ) : (
            <LinkButton
              to={WILLS_SIGN_UP_PATH}
              partnerCampaignReferral={isPartnerCampaignPage}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...(preselectedServiceIsValid(preselectedService)
                ? { preselectedService }
                : {})}
              telephoneWillsEnabled={telephoneWillsEnabled}
              productLink
              data-track-event="click"
              data-track-element="cta"
              data-track-slice="hero"
            >
              {ctaText}
            </LinkButton>
          )}

          <StyledTrustpilotSummary />
        </StyledContentWrapper>
        {partnerCustomMessage && partnerDisplayName && (
          <StyledPartnerCustomMessageWrapper>
            <PartnerCustomMessage
              partnerCustomMessage={partnerCustomMessage}
              partnerDisplayName={partnerDisplayName}
            />
          </StyledPartnerCustomMessageWrapper>
        )}

        <StyledAsideWrapper tag="aside" spanFromM={6}>
          <StyledBlob imagePath="textures/blob-1-yellow" imageWidth={400} cover>
            <StyledImage
              tag={AspectRatioWrapper}
              ratio={1337 / 752}
              imagePath="illustrations/tv-wills-hero"
              imageWidth={650}
              imageWidthFromM={400}
              imageWidthFromL={450}
              cover
            />
            <StyledTVAdButton
              onClick={() => setModalIsOpen(true)}
              icon={PlaySolidIcon}
              iconOnLeft
              iconMargin="XS"
              aria-haspopup="dialog"
            >
              Watch our TV ad
            </StyledTVAdButton>
          </StyledBlob>
        </StyledAsideWrapper>
      </Grid>

      {modalIsOpen && (
        <MediaModal close={() => setModalIsOpen(false)}>
          <iframe
            title="Farewill TV ad on YouTube"
            src="https://www.youtube.com/embed/JOCceLAzVVA?autoplay=1&rel=0&color=white&modestbranding=1"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </MediaModal>
      )}
    </StyledHero>
  )
}
export default Hero
